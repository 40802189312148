import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import Layout from '../components/layout';
import Seo from '../components/seo';

const config = {
  infinite: true,
  arrows: true,
  dots: true,
  swipe: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnFocus: true,
  autoplaySpeed: 10000
};

const IndexPage = ({ data }) => {
  return (
    <Layout className="home" data={data}>
      <Seo title="Home" data={data} />
      <div className="section mt-0 p-0">
        <Slider className="slider-hero" {...config}>
          <div className="slider-hero__slide">
            <GatsbyImage
              className="slider-hero__img"
              image={getImage(data.heroDipendenze)}
              alt=""
            />
            <div className="slider-hero__content">
              <div className="container-xl text-center pb-4">
                <div className="row">
                  <div className="col-12 col-md-10 offset-md-1 slider-hero__citation">
                    "Non si diventa dipendenti da una macchina, ma dalle sensazioni ed esperienze
                    che si provano durante l'uso di quella macchina..."
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-md-10 offset-md-1 slider-hero__author text-right">
                    Kimberly Young
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <Link to="/uno-zoom-sulle-dipendenze/" className="slider-hero__link">
                      Approfondisci le dipendenze
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-hero__slide">
            <GatsbyImage className="slider-hero__img" image={getImage(data.hero)} alt="" />
            <div className="slider-hero__content">
              <div className="container-xl text-center pb-4">
                <div className="row">
                  <div className="col-12 col-md-10 offset-md-1 slider-hero__citation slider-hero__citation--small">
                    "Ogni intervento, indipendentemente dalla patologia trattata e dalle tecniche
                    applicate, si discosterà, almeno in parte, da tutti gli altri, proprio perché
                    sarà progettato ad hoc per quella specifica persona, come un vestito cucito su
                    misura."
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <Link to="/chi-sono/il-mio-metodo-di-lavoro/" className="slider-hero__link">
                      Vedi il mio metodo di lavoro
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className="section">
        <div className="container-xl">
          <div className="row">
            <div className="col">
              <h1>Francesco Loffredo</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-3">
              <GatsbyImage
                style={{ maxWidth: '300px' }}
                image={getImage(data.profile)}
                alt="Francesco Loffredo"
              />
            </div>
            <div className="col-md-6 col-lg-8">
              <p className="text text--small text--card">
                <strong>Dott. Francesco Loffredo</strong>
                <br />
                Psicologo, Psicoterapeuta, Esperto in Nuove Dipendenze
                <br />
                Riceve su appuntamento a Pavia (PV), Alessandria (AL) e online
              </p>
              <p className="text">
                Attualmente lavoro come psicoterapeuta libero professionista presso due studi, a
                Pavia ed Alessandria. In entrambe le sedi conduco percorsi di
                <strong> psicoterapia individuale</strong> con adulti, giovani adulti e adolescenti;
                ad Alessandria mi occupo anche di <strong>apprendimento</strong> gestendo gruppi
                educativo - didattici con ragazzi adolescenti delle scuole superiori.
              </p>
              <p>
                <Link to="/chi-sono/" className="">
                  Leggi di più
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container-xl">
          <div className="row">
            <div className="col-12 col-md-8 d-flex align-items-center order-2 order-md-0 mt-3 mt-md-0">
              <div>
                <p>
                  Fin dall’inizio della mia pratica clinica, ho avuto modo di apprezzare le
                  potenzialità e la flessibilità dei colloqui online; questa esperienza mi ha
                  permesso di portare avanti la mia attività anche durante il periodo del lockdown.
                </p>
                <div className="">
                  <Link to="/contatti/" className="">
                    Contattami per un appuntamento
                  </Link>
                  <span>&nbsp;&nbsp;oppure&nbsp;&nbsp;</span>
                  <a
                    href="https://www.miodottore.it/francesco-loffredo-2/psicologo-clinico-psicoterapeuta/voghera?utm_source=widget-null&utm_medium=link"
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    visita il mio profilo su MioDottore
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-3 mt-md-0">
              <GatsbyImage
                style={{ maxWidth: '100%' }}
                image={getImage(data.skype)}
                alt="Francesco Loffredo su Skype"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container-xl">
          <div className="row">
            <div className="col-12 col-md-4 mt-3">
              <GatsbyImage
                style={{ maxWidth: '100%' }}
                image={getImage(data.fraPage)}
                alt="Francesco Loffredo Caricatura PAGE"
              />
            </div>
            <div className="col-12 col-md-8 d-flex align-items-center mt-3">
              <div>
                <p>
                  A partire da aprile 2020, ho iniziato a scrivere articoli per{' '}
                  <a
                    href="https://www.psygaming.page/"
                    title="PAGE"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    PAGE
                  </a>{' '}
                  (Psychology Application on Gaming Evolution), progetto che ho fondato, insieme ad
                  un gruppo di amici e colleghi, con l’obiettivo di indagare le complesse relazioni
                  tra psicologia, tecnologie e videogiochi.
                </p>
                <div>
                  <Link to="/eventi-pubblicazioni/#articoli-page">Vedi gli articoli di PAGE</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
export const query = graphql`
  query HomePageQuery {
    ...HeaderFragment
    ...SeoFragment
    heroDipendenze: file(relativePath: { eq: "tech-addiction.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
    hero: file(relativePath: { eq: "pexels-cottonbro-cut.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
    profile: file(relativePath: { eq: "loffre.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 450, layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
    skype: file(relativePath: { eq: "foto_2_skype.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 450, layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
    fraPage: file(relativePath: { eq: "caricatura_fra.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED, quality: 100, placeholder: TRACED_SVG)
      }
    }
  }
`;
